/* You can add global styles to this file, and also import other style files */

body { margin: 0; }

body, * {
  font-family: Poppins, "Helvetica Neue", sans-serif !important;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.ms-1 {
  margin-left: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-5 {
  padding: 3rem;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-multiline-content {
  white-space: pre-line;
}

.mat-expansion-panel-header {
  height: auto !important;

  min-height: 48px !important;
}

.mat-expansion-panel-header-title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-index-10 {
  z-index: 10;
}

.bg-white {
  background: #fff;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
